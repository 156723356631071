// src/pages/UnAuthenticatedHomeOld.tsx
import { memo } from 'react';
import { useAuth } from '../../context/AuthContext';

function Home() {
    const { user } = useAuth();
    const currentDate = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
    }).format(currentDate);

    const renderStep = (number: number, text: string, completed: boolean = false) => (
        <div className="flex items-center space-x-3">
            {completed ? (
                <div className="w-6 h-6 rounded-full bg-[#faf7f4] flex items-center justify-center">
                    <svg className="w-4 h-4 text-[#FB7C6A]" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M5 13l4 4L19 7"></path>
                    </svg>
                </div>
            ) : (
                <div className="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center">
                    <span className="text-gray-500 text-sm">{number}</span>
                </div>
            )}
            <span className="text-gray-600">{text}</span>
        </div>
    );

    return (
        <div className="p-8">
            <div className="mb-8">
                <p className="text-gray-600 text-sm mb-2">{formattedDate}</p>
                <h1 className="text-2xl font-semibold">Good morning, {user?.name}</h1>
            </div>

            <div className="grid grid-cols-2 gap-6">
                <div className="bg-white rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-semibold mb-4">FINISH YOUR LOAN APPLICATION</h2>
                    <div className="space-y-4">
                        {renderStep(1, 'Sign Up', true)}
                        {renderStep(2, 'Your Company', true)}
                        {renderStep(3, 'Accounting integration')}
                        {renderStep(4, 'Commerce integration')}
                        {renderStep(5, 'Banking integration')}
                    </div>
                </div>

                <div className="bg-white rounded-lg p-6 shadow-sm">
                    <h2 className="text-lg font-semibold mb-4">ACCOUNTING INTEGRATION</h2>
                    <p className="text-gray-600 mb-6">
                        You've sent an invite to Paul Weller (paul.weller@miptpre.com) on August 21st, 2024 but the integration has not been completed. You can send them a reminder below or provide someone else's details if they've changed.
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button className="px-4 py-2 border border-gray-300 rounded-full text-gray-700 hover:bg-gray-50">
                            CANCEL
                        </button>
                        <button className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 flex items-center">
                            SEND A REMINDER
                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Home);