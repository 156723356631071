// src/components/layout/TopNav.tsx
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useAuth } from '../../context/AuthContext';
import logo from '../../../assets/assembledbrands_logo.png';

const TopNav = memo(() => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    return (
        <div className="w-full bg-white border-b border-gray-200 px-8 py-4 flex justify-between items-center h-16">
            <div className="flex items-center space-x-6">
                <img src={logo} alt="Logo" className="h-8" />
            </div>
            <div className="flex items-center space-x-6">
                <button className="p-2 hover:bg-gray-100 rounded-full">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                </button>

                <Menu as="div" className="relative z-[9999]">
                    <Menu.Button className="flex items-center space-x-3">
                        <div className="w-8 h-8 rounded-full bg-[#FB7C6A] flex items-center justify-center text-white">
                            {user?.name?.[0]?.toUpperCase()}
                        </div>
                        <span>{user?.name}</span>
                    </Menu.Button>

                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-2">
                                <div className="px-4 py-2">
                                    <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                                    <p className="text-sm text-gray-500">{user?.email}</p>
                                    <p className="text-sm text-gray-500">Company: {(user as {'custom:company_name'?: string;} )?.['custom:company_name']}</p>
                                </div>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={handleLogout}
                                            className={`${
                                                active ? 'bg-gray-100' : ''
                                            } w-full text-left px-4 py-2 text-sm text-red-600 rounded-md`}
                                        >
                                            Log out
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
});

TopNav.displayName = 'TopNav';

export default TopNav;