import React from 'react';
import smileWoman from '../../../../../assets/home/smile-woman.jpg';
import {Link} from "react-router-dom";

const FirstStep = () => {
    return (
        <section className="py-16 bg-[#f8f1e4]">
            <div className="container mx-auto px-4">
                <div className="max-w-6xl mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                        {/* Contenido de texto */}
                        <div className="max-w-xl">
                            <h1 className="text-4xl md:text-5xl font-bold text-[#2D2D2D] mb-4">
                                Take your First Step
                                <br/>
                                <span className="text-[#E8927C]">in Financing Your Future</span>
                            </h1>

                            <p className="text-gray-700 text-lg mb-8">
                                Start your application, connect your accounts
                                <br/>
                                and get funded quickly.
                            </p>
                            <Link to="/signup">
                                <button
                                    className="bg-white text-black px-8 py-3 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                                    GET STARTED
                                </button>
                            </Link>

                            <div className="mt-6">
                                <span className="text-gray-600">Already have an account? </span>
                                <Link to="/login">
                                    <button className="text-[#E8927C] hover:text-[#d67b66] font-medium">
                                    Sign in.
                                    </button>
                                </Link>
                            </div>
                        </div>

                        {/* Imagen */}
                        <div>
                            <img
                                src={smileWoman}
                                alt="Woman smiling in orange outfit"
                                className="w-full h-auto rounded-lg shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstStep;