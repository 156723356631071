import { useState, useEffect } from 'react';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { ConnectionCallbackArgs } from "@codat/sdk-link-types";
import { CodatLink } from "../integration/CodatLink";

const apiUrl = process.env.REACT_APP_API_URL;

function Integrations() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [companyId, setCompanyId] = useState('');

    const closeCodatLink = (): void => {
        setLoading(false);
    }

    const onConnection = (connection: ConnectionCallbackArgs): void =>
        console.log(`On connection callback - ${connection.connectionId}`);

    const onClose = (): void => closeCodatLink();
    const onFinish = (): void => closeCodatLink();
    const onError = (): void => closeCodatLink();

    useEffect(() => {
        const initializeIntegration = async () => {
            setLoading(true);
            setError('');

            try {
                const session = await fetchAuthSession();
                const token = session.tokens?.idToken?.toString();

                if (!token) {
                    throw new Error('No authentication token available');
                }

                const response = await fetch(`${apiUrl}/api/codat`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    const errorData = await response.text();
                    throw new Error(errorData || 'Failed to fetch company ID');
                }

                const companyId = await response.text();
                setCompanyId(companyId);

                await getCurrentUser();
                await fetchAuthSession({ forceRefresh: true });

            } catch (err) {
                console.error('integration error:', err);
                setError(err instanceof Error ? err.message : 'Failed to connect to the server');
            } finally {
                setLoading(false);
            }
        };

        initializeIntegration();
    }, []); // Empty dependency array means this runs once on component mount

    return (
        <div className="h-full w-full flex flex-col">
            {error && (
                <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                </div>
            )}

            {loading ? (
                <div className="flex-1 flex items-center justify-center">
                    <p>Initializing...</p>
                </div>
            ) : companyId ? (
                <div className="flex-1 w-full h-full min-h-[500px]">
                    <CodatLink
                        companyId={companyId}
                        onConnection={onConnection}
                        onError={onError}
                        onClose={onClose}
                        onFinish={onFinish}
                        width="100%"
                        height="85vh"
                        maxHeight="95%"
                    />
                </div>
            ) : null}
        </div>
    );
}

export default Integrations;