import React from 'react';
import smileMan from '../../../../../assets/home/smile-man.jpg';

const MinimumRequirements = () => {
    const requirements = [
        "1+ years in business",
        "At least $5 million in annual revenue",
        "$1 million in inventory asset value at minimum",
        "$500K+ in accounts receivable asset value"
    ];

    return (
        <section className="py-16 bg-[#f8f1e4]">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center max-w-6xl mx-auto">
                    {/* Imagen */}
                    <div className="rounded-lg overflow-hidden">
                        <img
                            src={smileMan}
                            alt="Businessman checking inventory"
                            className="w-full h-auto object-cover"
                        />
                    </div>

                    {/* Requerimientos */}
                    <div>
                        <h2 className="text-3xl font-bold mb-8">
                            Minimum Requirements
                        </h2>

                        <div className="space-y-6">
                            {requirements.map((requirement, index) => (
                                <div
                                    key={index}
                                    className="pb-4 border-b border-gray-300"
                                >
                                    <p className="text-gray-800">
                                        {requirement}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MinimumRequirements;