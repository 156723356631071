// src/config/amplify.ts
import { Amplify } from 'aws-amplify';


const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const signUpVerificationMethod = process.env.REACT_APP_SIGN_UP_VERIFICATION_METHOD as "code" | "link";

export const configureAmplify = () => {
    if (!userPoolId) {
        throw new Error("REACT_APP_USER_POOL_ID is not define");
    }
    if (!userPoolClientId) {
        throw new Error("REACT_APP_USER_POOL_CLIENT_ID is not define");
    }

    if (signUpVerificationMethod !== "code" && signUpVerificationMethod !== "link") {
        throw new Error(`REACT_APP_SIGN_UP_VERIFICATION_METHOD must be 'code' or 'link'.
         REACT_APP_SIGN_UP_VERIFICATION_METHOD=${signUpVerificationMethod}`);
    }

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: userPoolId,
                userPoolClientId: userPoolClientId,
                signUpVerificationMethod: signUpVerificationMethod,
            }
        }
    });
};