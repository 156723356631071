// components/LeftNav/LeftNav.tsx
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Home, Link2, Network } from 'lucide-react';
import NavItem from "./NavItem";

const LeftNav = memo(() => {
    const location = useLocation();

    return (
        <div className="w-64 bg-[#2D2D2D] flex flex-col min-h-[calc(100vh-64px)]">
            <nav className="flex-1 py-8 space-y-2">
                <NavItem
                    to="/home"
                    icon={<Home className="stroke-current" size={20} />}
                    text="Home"
                    isActive={location.pathname === '/home'}
                />
                <NavItem
                    to="/integrations"
                    icon={<Link2 className="stroke-current" size={20} />}
                    text="Integrations"
                    isActive={location.pathname === '/integrations'}
                />
                <NavItem
                    to="/connections"
                    icon={<Network className="stroke-current" size={20} />}
                    text="Connections"
                    isActive={location.pathname === '/connections'}
                />
            </nav>
        </div>
    );
});

LeftNav.displayName = 'LeftNav';

export default LeftNav;