import React, { useEffect, useState } from "react";

import {initializeCodatLink } from "@codat/sdk-link-types";
import {CodatLinkOptions, ConnectionCallbackArgs, ErrorCallbackArgs} from "@codat/sdk-link-types/src/lib/types";

export type CodatLinkPropsCustom = {
    companyId: string;
    onClose?: () => void;
    onError: (args: ErrorCallbackArgs) => void;
    onFinish: () => void;
    onConnection: (args: ConnectionCallbackArgs) => void;
    onConnectionStarted?: (args: ConnectionCallbackArgs) => void;
    options?: CodatLinkOptions;
    accessToken?: string;
    width: string;
    height: string;
    maxHeight: string;
};

export const CodatLink: React.FC<CodatLinkPropsCustom> = (props: CodatLinkPropsCustom) => {
    const [componentMount, setComponentMount] = useState<HTMLDivElement | null>(
        null
    );

    useEffect(() => {
        const target = componentMount;
        if (target && target.children.length === 0) {
            initializeCodatLink(target, props);
        }
        // CodatLinkSDK does not support changing props after initialisation.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentMount]);

    return (
        <div
            style={{
                // Recommended dimensions
                width: props.width,
                height: props.height,
                maxHeight: props.maxHeight,
            }}
            ref={setComponentMount}
        ></div>
    );
};