import logo1 from '../../../../../assets/carrousel/1-carrusel.png';
import logo2 from '../../../../../assets/carrousel/2-carrusel.png';
import logo3 from '../../../../../assets/carrousel/3-carrusel.png';
import logo4 from '../../../../../assets/carrousel/4-carrusel.png';
import logo5 from '../../../../../assets/carrousel/5-carrusel.png';
import logo6 from '../../../../../assets/carrousel/6-carrusel.png';
import logo7 from '../../../../../assets/carrousel/7-carrusel.avif';
import logo8 from '../../../../../assets/carrousel/8-carrusel.png';
import logo9 from '../../../../../assets/carrousel/9-carrusel.png';
import logo10 from '../../../../../assets/carrousel/10-carrusel.avif';
import logo11 from '../../../../../assets/carrousel/11-carrusel.png';
import logo12 from '../../../../../assets/carrousel/12-carrusel.avif';
import logo13 from '../../../../../assets/carrousel/13-carrusel.png';
import logo14 from '../../../../../assets/carrousel/14-carrusel.png';
import logo15 from '../../../../../assets/carrousel/15-carrusel.png';

const InfiniteLogoCarousel = () => {
    const logos = [
        { src: logo1, alt: 'Logo 1' },
        { src: logo2, alt: 'Logo 2' },
        { src: logo3, alt: 'Logo 3' },
        { src: logo4, alt: 'Logo 4' },
        { src: logo5, alt: 'Logo 5' },
        { src: logo6, alt: 'Logo 6' },
        { src: logo7, alt: 'Logo 7' },
        { src: logo8, alt: 'Logo 8' },
        { src: logo9, alt: 'Logo 9' },
        { src: logo10, alt: 'Logo 10' },
        { src: logo11, alt: 'Logo 11' },
        { src: logo12, alt: 'Logo 12' },
        { src: logo13, alt: 'Logo 13' },
        { src: logo14, alt: 'Logo 14' },
        { src: logo15, alt: 'Logo 15' }
    ];

    const duplicatedLogos = [...logos, ...logos];

    return (
        <section className="w-full bg-[#111111] py-8 overflow-hidden">
            <div className="relative max-w-full mx-auto">
                <div className="absolute w-24 left-0 top-0 bottom-0 bg-gradient-to-r from-[#111111] to-transparent z-10"></div>
                <div className="absolute w-24 right-0 top-0 bottom-0 bg-gradient-to-l from-[#111111] to-transparent z-10"></div>

                <div className="flex animate-carousel hover:pause">
                    {duplicatedLogos.map((logo, index) => (
                        <div
                            key={index}
                            className="flex-none w-[180px] mx-6 flex items-center justify-center"
                        >
                            <img
                                src={logo.src}
                                alt={logo.alt}
                                className="h-8 w-auto object-contain brightness-0 invert opacity-90 hover:opacity-100 transition-opacity duration-300"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InfiniteLogoCarousel;