// ConnectionManagement.tsx
import {
    DisconnectCallbackArgs,
    ErrorCallbackArgs,
} from "@codat/sdk-connections";

import {CodatConnections} from "./CodatConnections";

export const ConnectionManagement = ({accessToken}: {
    accessToken: string;
}) => {
    const onDisconnect = (connection: DisconnectCallbackArgs) =>
        alert(`On disconnect callback - ${connection.connectionId}`);
    const onReconnect = (connection: DisconnectCallbackArgs) =>
        alert(`On reconnect callback - ${connection.connectionId}`);
    const onClose = () => console.log('close');
    const onError = (error: ErrorCallbackArgs) =>
        alert(`On error callback - ${error.message}`);

    return (
        <CodatConnections
            accessToken={accessToken}
            onDisconnect={onDisconnect}
            onReconnect={onReconnect}
            onError={onError}
            onClose={onClose}
        />
    );
};