import { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import singUp from '../../../../assets/signup.jpg';
import logo from '../../../../assets/assembledbrands_logo.png';

interface LocationState {
    email: string;
}

function VerifyEmail() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = (location.state as LocationState)?.email;
    const [mounted, setMounted] = useState<boolean>(false);

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [resending, setResending] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            await confirmSignUp({
                username: email,
                confirmationCode: verificationCode
            });

            navigate('/login', {
                state: { message: 'Email verified successfully! Please log in.' }
            });
        } catch (error) {
            console.error('Error confirming sign up:', error);
            setError(error instanceof Error ? error.message : 'Error verifying email');
        } finally {
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setError('');
        setResending(true);
        try {
            await resendSignUpCode({ username: email });
            alert('Verification code resent successfully!');
        } catch (error) {
            console.error('Error resending code:', error);
            setError(error instanceof Error ? error.message : 'Error resending verification code');
        } finally {
            setResending(false);
        }
    };

    if (!email) {
        return (
            <div className={`min-h-screen bg-[#faf7f4] flex items-center justify-center transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className="text-center">
                    <h2 className="text-xl font-bold mb-4">Invalid Access</h2>
                    <p className="mb-4">Please sign up first to verify your email.</p>
                    <Link to="/signup" className="text-blue-600 hover:text-blue-800 underline transition-colors duration-300">
                        Go to Sign Up
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#faf7f4] flex items-center justify-center p-4">
            <div className={`bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-5xl flex transform transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                {/* Left side - Image */}
                <div className={`w-1/2 transition-all duration-1000 delay-300 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
                    <img
                        src={singUp}
                        alt="Business handshake"
                        className="object-cover w-full h-full"
                    />
                </div>

                {/* Right side - Verification Form */}
                <div className={`w-1/2 flex flex-col justify-center px-12 py-8 bg-[#F8F1E4] transition-all duration-1000 delay-500 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
                    <div className="max-w-md w-full mx-auto">
                        <div className={`flex justify-center mb-8 transition-all duration-700 delay-700 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                            <img
                                src={logo}
                                alt="Company Logo"
                                className="h-16 w-auto"
                            />
                        </div>

                        <div className={`mb-8 transition-all duration-700 delay-800 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <h2 className="text-xl font-bold text-black mb-2">
                                VERIFY YOUR EMAIL
                            </h2>
                            <p className="text-gray-600">
                                We've sent a verification code to {email}.
                                Please check your inbox and enter the code below.
                            </p>
                        </div>

                        {error && (
                            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded animate-shake">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className={`space-y-6 transition-all duration-700 delay-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                                    Verification Code
                                </label>
                                <input
                                    type="text"
                                    id="verificationCode"
                                    value={verificationCode}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value)}
                                    placeholder="Enter verification code"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                    required
                                />
                            </div>

                            <div className="flex flex-col space-y-4">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full bg-black text-white py-2 px-4 rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-50 transition-all duration-300 transform hover:scale-105"
                                >
                                    {loading ? 'VERIFYING...' : 'VERIFY EMAIL'}
                                </button>

                                <button
                                    type="button"
                                    onClick={handleResendCode}
                                    disabled={resending}
                                    className="text-sm text-gray-600 hover:text-gray-900 underline transition-colors duration-300"
                                >
                                    {resending ? 'Sending...' : 'Resend verification code'}
                                </button>

                                <div className="text-center">
                                    <Link
                                        to="/login"
                                        className="text-sm text-gray-600 hover:text-gray-900 transition-colors duration-300 hover:scale-105 inline-block"
                                    >
                                        Back to Login
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyEmail;