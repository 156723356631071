// components/DashboardLayout/DashboardLayout.tsx
import { memo } from 'react';
import TopNav from "./TopNav";
import LeftNav from "./LeftNav";


interface DashboardLayoutProps {
    children: React.ReactNode;
}

function DashboardLayout({ children }: DashboardLayoutProps) {
    return (
        <div className="min-h-screen">
            <TopNav />
            <div className="flex">
                <LeftNav />
                <div className="flex-1 bg-[#faf7f4]">
                    <main className="p-6">{children}</main>
                </div>
            </div>
        </div>
    );
}

export default memo(DashboardLayout);