import React from 'react';
import {Link} from "react-router-dom";

const WeUnderstandBusiness = () => {
    return (
        <section className="">
            {/* Contenido Principal */}
            <div className="container mx-auto px-4 py-16 bg-[#fffaf5]">
                <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-bold mb-6">
                        We Understand Your Business Better
                    </h2>

                    <p className="text-gray-700 text-lg mb-10">
                        We started out as brand founders of an up-and-coming fashion business, and fully understand
                        the challenges you face. Our growth lines of credit are designed with founders in mind, so you
                        can focus on running a successful business.
                    </p>

                    <Link to="/signup">
                        <button
                            className="bg-white text-black px-8 py-3 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                            APPLY NOW
                        </button>
                    </Link>


                    <div className="mt-4">
                        <span className="text-gray-600">Already have an account? </span>
                        <Link to="/login">
                            <button className="text-[#E8927C] hover:text-[#d67b66] font-medium">
                                Sign in.
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="border-t border-gray-200 bg-[#f9f1e6]">
                <div className="container mx-auto px-4 py-6">
                    <div className="flex justify-between items-center">
                        <nav className="space-x-6">
                            <button className="text-gray-600 hover:text-gray-800">
                                Terms of Use
                            </button>
                            <button className="text-gray-600 hover:text-gray-800">
                                Privacy
                            </button>
                        </nav>
                        <Link to="/login">
                            <button className="text-[#E8927C] hover:text-[#d67b66] font-medium">
                                Sign In
                            </button>
                        </Link>
                    </div>
                </div>
            </footer>
        </section>
    );
};

export default WeUnderstandBusiness;