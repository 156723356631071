interface ModalProps {
    isOpen: boolean;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div
                className="relative bg-white rounded-lg shadow-xl"
                onClick={(e) => e.stopPropagation()}
                style={{
                    width: "auto",
                    maxWidth: "90vw",
                    maxHeight: "90vh",
                    overflow: "auto",
                }}
            >
                <div className="p-4">{children}</div>
            </div>
        </div>
    );
};

export default Modal;