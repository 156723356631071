// src/context/AuthContext.tsx
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';


interface User {
    email: string;
    name?: string;
    'custom:brandName'?: string;
}

interface AuthContextType {
    user: User | null;
    setUser: (user: User | null) => void;
    isLoading: boolean;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User | null>(() => {
        // Intentar recuperar usuario del sessionStorage al iniciar
        const storedUser = sessionStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkUser();
    }, []);

    async function checkUser() {
        try {
            const attributes = await fetchUserAttributes();

            const userData: User = {
                email: attributes.email || '',
                name: attributes.name,
                // 'custom:company_name': attributes['custom:company_name'],
            };

            setUser(userData);
            sessionStorage.setItem('user', JSON.stringify(userData));
        } catch (error) {
            setUser(null);
            sessionStorage.removeItem('user');
            console.error('Error signing out:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const logout = async () => {
        try {
            await signOut();
            setUser(null);
            sessionStorage.removeItem('user');
        } catch (error) {
            console.error('Error checking User:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, setUser, isLoading, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}