import React from 'react';
import InfiniteLogoCarousel from "./components/InfiniteLogoCarousel";
import TopNav from "./components/TopNav";
import FirstStep from "./components/FirstStep";
import HowOurAppWorks from "./components/HowOurAppWorks";
import MinimumRequirements from "./components/MinimumRequirements";
import WeUnderstandBusiness from "./components/WeUnderstandBusiness";

const UnAuthenticatedHome = () => {
    return (
        <>
            <TopNav/>
            <FirstStep/>
            <HowOurAppWorks/>
            <MinimumRequirements/>
            <InfiniteLogoCarousel/>
            <WeUnderstandBusiness/>
        </>
    );
};

export default UnAuthenticatedHome;